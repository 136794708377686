/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-06-23",
    versionChannel: "nightly",
    buildDate: "2023-06-23T00:25:27.882Z",
    commitHash: "e0cc7f1e544044ba78568df1152366631bc86153",
};
